import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionRangeMaybe = props => {
  const { heading, value, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;

  const [from, to] = value && value.length > 1 ? [value[0], value[value.length - 1]] : [null, null];

  return from && to ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>
        {from} - {to}
      </p>
    </div>
  ) : null;
};

export default SectionRangeMaybe;
